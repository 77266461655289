import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

export default function Error404({ subTitle }) {
  return (
    <div>
      <Result
        status=""
        title="404"
        subTitle={subTitle || 'Sorry, the page you visited does not exist.'}
        extra={
          <Link to={'/'}>
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    </div>
  );
}
